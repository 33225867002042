import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import registerSchema from "../utils/registerSchema";
import Link from "next/link";
import SelectInput from "../components/common/SelectInputRegister";
import TextInput from "../components/common/TextInputRegister";
import api from "../src/api";
import ErrorAlert from "../components/alerts/error.js";
import SuccessAlert from "../components/alerts/success.js";
import Loader from "react-loader-spinner";
import { signIn } from "next-auth/client";
import Layout from "../components/common/layout.js";
import { getSession } from "next-auth/client";
import redirects from "../src/api/redirects.js";
import { getParams } from "../src/utils";

import Image from "next/image";

import GreenLogo from "../public/img/green-logo-2.png";
// import Globe from "../public/img/Globe.svg";
import Touch from "../public/img/Boehringer_Standalone_Touch_RGB_Accent-Green.png";

//show the other dropdowns

export default function Register({ specialties, countries, states }) {
  const [loading, setLoading] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const [successRegister, setSuccessRegister] = useState(false);
  const [message, setMessage] = useState("");
  const [validationMessages, setValidationMessages] = useState([]);

  const [showState, setShowState] = useState(false);
  const [state, setState] = useState(0);

  const schema = registerSchema([
    {
      name: "name",
      type: "String",
    },
    {
      name: "fatherLastName",
      type: "String",
    },
    {
      name: "professionalID",
      type: "String",
    },
    {
      name: "phone",
      type: "Number",
    },
    {
      name: "email",
      type: "Email",
    },
    {
      name: "confirmEmail",
      type: "Email",
    },
    {
      name: "password",
      type: "Password",
    },
    {
      name: "confirmPassword",
      type: "Password",
    },
    {
      name: "privacy",
      type: "Boolean",
    },
    {
      name: "specialty",
      type: "Select",
      options: ["Selecciona una especialidad"],
    },
    {
      name: "country",
      type: "Select",
      options: ["Selecciona un país"],
    },
  ]);

  const { register, handleSubmit, errors, clearErrors, formState } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const login = (
    email,
    accessToken,
    refreshToken,
    userID,
    license_verified,
    specialty_id
  ) => {
    let params = getParams();

    let url = `${process.env.NEXTAUTH_URL}/${
      params?.status
        ? `${params.status}/${params.url}/${params.url2 ? params.url2 : ""}`
        : ""
    }`;
    console.log(url);
    signIn("credentials", {
      email: email,
      access_token: accessToken,
      refresh_token: refreshToken,
      license_verified: license_verified,
      user_id: userID,
      role: 1,
      specialty_id: specialty_id,
      callbackUrl: `${url}`,
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorRegister(false);

    try {
      let myMedicalUnits = "";
      let myMedicalUnitsArray = [];
      let checkboxes = document.querySelectorAll<HTMLInputElement>(
        "input[type=checkbox]"
      );
      checkboxes.forEach((row) => {
        if (row.checked) myMedicalUnitsArray.push(row.value);
      });
      myMedicalUnits = myMedicalUnitsArray.join(",");

      if (myMedicalUnits) {
        myMedicalUnits = myMedicalUnits.replace("on", "");
        myMedicalUnits = myMedicalUnits.replace(/,\s*$/, "");
      }

      const {
        name,
        email,
        confirmEmail,
        password,
        confirmPassword,
        phone,
        specialty,
        country,
        fatherLastName,
        professionalID,
        privacy,
      } = data;

      const register = await (
        new api.Register(
          name,
          email,
          confirmEmail,
          password,
          confirmPassword,
          phone,
          specialty,
          country,
          fatherLastName,
          professionalID,
          privacy
        ) as any
      ).doMutation();

      if (!register || !register?.status)
        setErrorRegister(true), setMessage(register?.message);

      if (register.status)
        setSuccessRegister(true),
          setMessage(register?.message),
          login(
            email,
            register.access_token,
            register.refresh_token,
            register.user.id,
            register.user.license_verified,
            register.user.specialty.id
          );
    } catch (err) {
      console.log("err->", err);
      let validationErrors = null;

      if (err.hasOwnProperty("graphQLErrors"))
        validationErrors = err.graphQLErrors[0]?.extensions?.validation;

      if (validationErrors) setValidationMessages(validationErrors);

      setMessage("There was an error, please try again later");
      setErrorRegister(true);
      setSuccessRegister(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full">
      <div className="h-auto w-screen flex flex-col flex-">
        <div className="flex bg-white h-full w-full flex-wrap">
          <div
            className="w-full lg:w-1/2 flex flex-col justify-center lg:justify-start items-center h-96 lg:h-auto"
            style={{
              background: "url(/img/sign-up-bg-green.jpg) center no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="flex justify-center items-center flex-col h-1/2 lg:h-auto w-full lg:w-3/6 lg:mt-72">
              <div className="flex flex-col gap-x-3 w-full items-center">
                <Image src={GreenLogo} alt="" className="w-40 h-auto" />

                <div className="flex items-end">
                  <p className="text-bi-green-logo">Click</p>
                  <Image src={Touch} alt="" className="w-10 h-auto" />
                </div>
                <p className="text-white font-bold">Digital video library</p>
              </div>

              <p className="text-white text-sm mt-1">
                Medical education just a click away
              </p>
              <label className="text-sm px-5 py-3 rounded-full bg-white my-5 uppercase font-semibold w-auto text-center">
                LIVESTREAMING & ON DEMAND
              </label>
            </div>
          </div>

          <form
            className="w-full lg:w-1/2 py-5 px-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <div className="w-full flex justify-end items-center absolute top-5 right-5 lg:static">
                <Link href="/login">
                  <div className="bg-bi-green-malachite-800 rounded-md px-5 py-3 cursor-pointer hover:bg-bi-green-malachite-700 transition-colors duration-300">
                    <p className="text-white text-base tracking-wide font-semibold">
                      Log in
                    </p>
                  </div>
                </Link>
              </div>
              <h2 className="text-4xl font-bold mt-5 tracking-tight mb-4">
                Create new account
              </h2>

              <div className="flex flex-col items-stretch mb-2">
                <TextInput
                  label="Name"
                  name="name"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="flex items-stretch my-5 flex-wrap lg:flex-nowrap">
                <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                  <TextInput
                    label="Last name"
                    name="fatherLastName"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                  <SelectInput
                    name="specialty"
                    label="Specialty"
                    options={specialties}
                    optDefault="Select"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="flex items-stretch my-5 flex-wrap lg:flex-nowrap">
                <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                  <SelectInput
                    name="country"
                    label="Country"
                    options={countries}
                    optDefault="Select"
                    register={register}
                    errors={errors}
                  />
                </div>

                <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                  <TextInput
                    label="Professional license"
                    name="professionalID"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="flex flex-col items-stretch my-5">
                <TextInput
                  label="Phone"
                  name="phone"
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex flex-col items-stretch my-5">
                <TextInput
                  label="Email"
                  name="email"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="flex flex-col items-stretch my-5">
                <TextInput
                  label="Confirm email"
                  name="confirmEmail"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="flex items-stretch mt-5 mb-3 flex-wrap lg:flex-nowrap">
                <div className="w-full lg:w-2/4 lg:mr-5 mb-7 lg:mb-0">
                  <TextInput
                    password
                    label="Password"
                    name="password"
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="w-full lg:w-2/4 ">
                  <TextInput
                    password
                    label="Confirm password"
                    name="confirmPassword"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
              <p className="text-sm text-gray-400 mb-5">
                Password must be at least 4 characters long (letters or
                numbers). The password distinguishes between uppercase and
                lowercase letters.
              </p>

              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <input
                    id="privacy"
                    name="privacy"
                    type="checkbox"
                    className="h-4 w-4 text-bi-green-malachite-500 focus:ring-deep-sky-blue border-gray-300 rounded"
                    ref={register}
                  />
                  <label
                    htmlFor="privacy"
                    className="ml-2 block text-sm text-gray-700 leading-relaxed"
                  >
                    In accordance with the Federal Law on Protection of Personal
                    Data Held by Individuals, I have a clear knowledge of the
                    content and scope of the{" "}
                    <a
                      target="_blank"
                      href="/pdf/PRIVACY_NOTICE_HCPs_ENGLISH_MAR_3_2021.pdf"
                      className="cursor-pointer text-bi-green-malachite-700"
                    >
                      privacy notice
                    </a>{" "}
                    of Boehringer-Ingelheim México, SA DE CV., granting with
                    this signature my attention to personal data. As it is
                    established in it.
                  </label>
                </div>
              </div>
              <p className="text-xs text-red-400 pl-5">
                {errors.privacy?.message && "Must agree our privacy notice"}
              </p>
              <div className="w-full flex justify-center my-5">
                <button
                  type="submit"
                  disabled={loading ? true : false}
                  className="flex justify-center py-3 px-20 lg:px-10 uppercase border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-bi-green-malachite-800 hover:bg-bright-sky-blue transition ease-in duration-100 focus:outline-none tracking-widest"
                >
                  Complete Registration
                  {loading && (
                    <Loader
                      type="ThreeDots"
                      color="#FFFFFF"
                      height={20}
                      width={20}
                    />
                  )}
                </button>
              </div>
            </div>

            <div className="mt-4 flex align-middle justify-center">
              {errorRegister && (
                <ErrorAlert
                  message={
                    Object.keys(validationMessages).length
                      ? validationMessages
                      : message
                  }
                />
              )}
              {successRegister && <SuccessAlert message={message} />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export async function getServerSideProps(context) {
  const session = await getSession(context);

  if (session) return redirects.guest();

  let specialties = await (
    new api.GetRegisterDropdownValues("getSpecialties") as any
  ).doQuery();

  let countries = await (
    new api.GetRegisterDropdownValues("getCountries") as any
  ).doQuery();

  let states = await (
    new api.GetRegisterDropdownValues("GetStates") as any
  ).doQuery();

  let workingPlaces = await (
    new api.GetRegisterDropdownValues("GetWorkingPlaces") as any
  ).doQuery();

  return {
    props: {
      specialties,
      countries,
      states,
      workingPlaces,
    },
  };
}
